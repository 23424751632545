import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import useUpdateCities from '#hooks/useUpdateCities';
import styles from './citySelect.module.css';

const logo = require('#assets/images/logo.png');

const CitySelect = ({ city, changeCity, cities, updateCities, loading }) => {
  const [dropdown, setDropdown] = React.useState(false);
  const toggleDropdown = () => setDropdown(prevState => !prevState);

  useUpdateCities(updateCities, loading);

  const onChangeCity = city => {
    changeCity(city, 10);
    toggleDropdown();
  };

  const selectedCity = React.useMemo(() => {
    let selected = null;
    if (cities.length > 0) {
      selected = cities.find(x => x.id_city === city);
    }
    return selected;
  }, [cities, city]);

  return (
    <div data-testid="CitySelect">
      <div className={`${styles['wrapper']}`} style={{ backgroundColor: selectedCity ? selectedCity.color : 'transparent' }}>
        <button type="button" className={`btn btn-link ${styles['button']}`} /*onClick={toggleDropdown}*/>
          <img src={selectedCity ? `${process.env.REACT_APP_IMG_CIUDAD}/${selectedCity.logo}` : logo} alt="Icono" />
          <span>{selectedCity && selectedCity.name}</span>
          {/*<FontAwesomeIcon icon={dropdown ? faCaretUp : faCaretDown} />*/}
        </button>
        {dropdown && (
          <ul className={styles['list']}>
            {cities
              .filter(x => x.id_city !== city)
              .map(c => (
                <li key={c.id_city}>
                  <button
                    type="button"
                    className={`btn btn-sm btn-primary ${styles['list-button']}`}
                    style={{ backgroundColor: c.color, borderColor: c.color }}
                    onClick={() => onChangeCity(c.id_city)}
                  >
                    <img src={`${process.env.REACT_APP_IMG_CIUDAD}/${c.logo}`} alt="Icono" />
                    <span>{c.name}</span>
                  </button>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

CitySelect.propTypes = {
  city: PropTypes.string,
  changeCity: PropTypes.func,
  cities: PropTypes.array,
  updateCities: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

CitySelect.defaultProps = {
  city: '7',
  changeCity: f => f,
  cities: [],
  updateCities: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({
    city: state.city,
    cities: state.cities,
  }),
  dispatch => ({
    changeCity: city => dispatch(actions.changeCity(city)),
    updateCities: cities => dispatch(actions.citiesUpdate(cities)),
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(CitySelect);
