import React from 'react';
import PropTypes from 'prop-types';
import of from 'await-of';
import { WafoFormSelect } from '@wafo/forms';
import { Switch, Route, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faAngleRight, faCircle, faQrcode, faSort, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { connect as reduxConnect } from 'react-redux';
import { actions } from '#redux/reducers';
import LeafletStationsMap from '#components/maps/stationsMap/stationsMap';
import { roAPI } from '#utils/axiosAPI';
import styles from './stationsMap.module.css';

import { saveAs } from 'file-saver';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StationsMap = ({ match, loading }) => {

  //funcs
  const [tipoLista, setTipoLista] = React.useState(1); //1- Lista normal (evento de clicks) 2-Dnd (para ordenar las estaciones) 3-checkboxes para exportar qrs
  const [cities, setCities] = React.useState([]);
  const [city, setCity] = React.useState('');
  const [routes, setRoutes] = React.useState([]);
  const [idRoute, setIdRoute] = React.useState('');
  const [route, setRoute] = React.useState('');
  const [stations, setStations] = React.useState([]);
  // order
  const [originalStations, setOriginalStations] = React.useState([]);
  //QR func
  const [qrStations, setQrStations] = React.useState([]);
  //Map
  const [selectedStation, setSelectedStation] = React.useState('');

  const isAllSelected = qrStations.length === stations.length;

  React.useEffect(() => {
    async function fetchCities() {
      loading.set();
      const [res, err] = await of(
        roAPI.get('/cities', {
          params: {
            disabled: false,
          },
        }),
      );
      if (!err) {
        setCities(res.rows);
      }
      loading.stop();
    }
    fetchCities();
  }, []);

  React.useEffect(() => {
    async function fetchRoutes() {
      setIdRoute('');
      setRoute('');
      setStations([]);
      setTipoLista(1);
      loading.set();
      const [res] = await of(
        roAPI.get(`/routes/city/${city}`, {
          params: {
            nocache: true,
            disabled: '0,1',
          },
        }),
      );
      setRoutes(res);
      loading.stop();
    }
    if (city > 0) fetchRoutes();
  }, [city]);

  React.useEffect(() => {
    async function fetchStations() {
      setStations([]);
      setTipoLista(1);
      loading.set();
      const [res] = await of(
        roAPI.get(`/stations/all?id_route=${idRoute}`, {
          params: {
            nocache: true,
            disabled: '0,1',
          },
        }),
      );
      setOriginalStations(res);
      setStations(res);
      loading.stop();
    }
    if (route !== '') fetchStations();
  }, [route]);

  const handleCityChange = ({ target: { value } }) => setCity(value);
  const handleRouteChange = ({ target: { value } }) => {
    setIdRoute(value);
    for (let r of routes) {
      if (r.id_route == value) {
        setRoute(r)
        break;
      }
    }
  };

  //Evento de lista (drag)
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const updatedStations = Array.from(stations);
    const [reorderedStation] = updatedStations.splice(result.source.index, 1);
    updatedStations.splice(result.destination.index, 0, reorderedStation);

    setStations(updatedStations);
  };

  //Evento de checkboxes
  const handleCheckboxChange = (code) => {
    setQrStations((prevSelectedStations) => {
      const exists = prevSelectedStations.some(station => station.code === code);
      if (exists) {
        return prevSelectedStations.filter(station => station.code !== code);
      } else {
        return [...prevSelectedStations, { code, id_route: idRoute }];
      }
    });
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allStations = stations.map((station) => ({
        code: station.code,
        id_route: idRoute
      }));
      setQrStations(allStations);
    } else {
      setQrStations([]);
    }
  };

  const setDraggableList = () => {
    setTipoLista(2);
  }

  const setCheckboxList = () => {
    setTipoLista(3);
  }

  const cancelAction = () => {
    setStations(originalStations);
    setQrStations([]);
    setTipoLista(1);
  }

  const submitAction = () => {
    switch (tipoLista) {
      //order
      case 2:
        submitOrder();
        break;
      //qr
      case 3:
        downloadQR();
        break;
      default:
        break;
    }
  };

  //TODO: agregar mensaje de éxito a estas madres

  const submitOrder = async () => {
    try {
      loading.set();
      const res = await roAPI.post('/stations/' + idRoute + '/order', { stations });
      setStations(res);
      loading.stop();
      setTipoLista(1);
    } catch (err) {
      console.error(err);
      loading.stop();
    }
  }

  const downloadQR = async () => {
    loading.set();
    const [res, err] = await of(
      roAPI.post(`/stations/qr`, {
        stations: qrStations
      }, {
        responseType: 'blob',
      }),
    );
    if (!err && qrStations.length > 0) {
      debugger;
      const blob = new Blob([res.data], { type: qrStations.length == 1 ? 'image/png' : 'application/zip' });
      saveAs(blob, qrStations.length == 1 ? 'qr-code' : 'qr-codes');
    }
    loading.stop();
  };

  return (
    <div className="row">
      {/** selects y botones */}
      {/*JSON.stringify(city)}
      <br></br>
      {JSON.stringify(route)}
      <br></br>
      {JSON.stringify(selectedStation)
      {JSON.stringify(stations)}
      {JSON.stringify(qrStations)}*/}
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-3">
            <WafoFormSelect
              name="city"
              customClass="col-12 no-padding"
              label="Ciudad"
              defaultValue="Selecciona la ciudad"
              options={cities.map(x => ({
                value: x.id_city,
                display: x.name,
              }))}
              value={city}
              onChangeCallback={handleCityChange}
            />
          </div>
          <div className="col-12 col-md-3">
            <WafoFormSelect
              name="route"
              customClass="col-12 no-padding"
              label="Línea"
              defaultValue="Selecciona la línea"
              options={routes.map(x => ({
                value: x.id_route,
                display: x.name,
              }))}
              value={idRoute}
              onChangeCallback={handleRouteChange}
              extraProps={{ disabled: city == '' }}
            />
          </div>
          <div className="col-12 col-md-6 text-right">
            {tipoLista == 1 &&
              <>
                <button type="button" className="btn btn-primary" onClick={setDraggableList} style={{ marginTop: '20px', marginLeft: '10px' }} disabled={stations.length < 2}>
                  <FontAwesomeIcon icon={faSort} style={{ marginRight: '0.5rem' }} />
                  <span>Cambiar orden</span>
                </button>
                <button type="button" className="btn btn-primary" onClick={setCheckboxList} style={{ marginTop: '20px', marginLeft: '10px' }} disabled={stations.length < 1}>
                  <FontAwesomeIcon icon={faQrcode} style={{ marginRight: '0.5rem' }} />
                  <span>Generar QRs</span>
                </button>
                <Route
                  exact
                  path={`${match.url}/`}
                  render={() => (
                    <Link to="/panel/estaciones/estacion" className="btn btn-primary" style={{ marginTop: '20px', marginLeft: '10px' }}>
                      <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
                      Agregar estación
                    </Link>
                  )}
                />
              </>
            }
          </div>
        </div>
      </div>
      {/** lista estaciones */}
      <div className="col-12 col-md-4">
        <div className="row">
          <div className={`col-12 ${styles['lines-list']}`}>
            <label>Estaciones en la línea</label>
            {tipoLista == 1 && stations.length > 0 && (
              <ul>
                {stations.map(r => (
                  <li key={r.id_station}>
                    <div onClick={() => setSelectedStation(r)}>
                      <span>{r.code} - {r.name}</span>
                      <div>
                        <Link
                          className="btn btn-sm btn-link"
                          to={{ pathname: `/panel/estaciones/estacion/${r.id_station}`, state: { route: r } }}
                        >
                          Editar
                        </Link>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {tipoLista == 2 && (
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="stations">
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef}>
                      {stations.map(({ id_station, code, name }, index) => (
                        <Draggable key={id_station} draggableId={id_station} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {code} - {name}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {tipoLista == 3 && (
              <ul>
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="select-all-checkbox"
                      onChange={handleSelectAllChange}
                      checked={isAllSelected}
                    />
                    <label className="form-check-label" htmlFor="select-all-checkbox">
                      Seleccionar todos
                    </label>
                  </div>
                </li>
                {stations.map(({ id_station, code, name }) => (
                  <li key={code}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`checkbox-${code}`}
                        checked={qrStations.some(station => station.code === code)}
                        onChange={() => handleCheckboxChange(code)}
                      />
                      <label className="form-check-label" htmlFor={`checkbox-${code}`}>
                        {code} - {name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {stations.length < 1 && <p className={styles['empty']}>Sin estaciones asignadas.</p>}
          </div>
          {tipoLista != 1 && (
            <div className='col-12 text-center'>
              {/** TODO: darle funcionalidad a esta madre (order, qr) */}
              <button type="button" className="btn btn-primary" onClick={submitAction} style={{ marginTop: '20px', marginLeft: '10px' }}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '0.5rem' }} />
                <span>Aceptar</span>
              </button>
              <button type="button" className="btn btn-danger" onClick={cancelAction} style={{ marginTop: '20px', marginLeft: '10px' }}>
                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '0.5rem' }} />
                <span>Cancelar</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {/** mapa */}
      <div className="col-12 col-md-8">
        <div className={styles['map']}>
          <LeafletStationsMap city={city} route={route} cities={cities} stations={stations} selectedStation={selectedStation} mapMode={''} />
        </div>
      </div>
    </div>
  );
};

StationsMap.propTypes = {
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
};

StationsMap.defaultProps = {
  loading: {
    set: f => f,
    stop: f => f,
  },
};

export default reduxConnect(
  state => ({}),
  dispatch => ({
    loading: {
      set: () => dispatch(actions.loadingSet()),
      stop: () => dispatch(actions.loadingStop()),
    },
  }),
)(StationsMap);
