import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import BooleanButtons from '../customForms/booleanButtons/booleanButtons';

import SelectCoordinateMap from '#components/maps/selectCoordinateMap/selectCoordinateMap';

const FormLine = ({ id, onSubmit, initialValues, cities, onCityChange, setRoute, city, route, station, onClick, routes }) => {

  const initialV = React.useMemo(() => {
    return {
      ...initialValues,
    };
  }, [initialValues]);

  return (
    <div>
      <WafoForm formId="formStation" locale="es" onSubmit={onSubmit} values={initialV}>
        <div className="col-12 col-md-4">
          <div className="row">
            <WafoFormSelect
              name="id_city"
              customClass="col-12"
              label="Ciudad"
              defaultValue="Selecciona la ciudad"
              options={cities.map(x => ({
                value: x.id_city,
                display: x.name,
              }))}
              validations={{ required: true }}
              onChangeCallback={onCityChange}
              extraProps={{
                disabled: id,
              }}
            />

            {!id && <WafoFormSelect
              name="id_route"
              customClass="col-12"
              label="Línea"
              defaultValue="Selecciona la línea"
              options={routes.map(x => ({
                value: x.id_route,
                display: x.name,
              }))}
              validations={{ required: true }}
              onChangeCallback={setRoute}
            />}

            <WafoFormInput
              name="code"
              label="Código"
              placeholder="Código"
              customClass="col-12"
              validations={{ required: true }}
            />

            <WafoFormInput
              name="name"
              label="Nombre"
              placeholder="Nombre"
              customClass="col-12"
              validations={{ required: true }}
            />

            {id && <>
              <BooleanButtons
                name="disabled"
                label="Estación activa"
                customClass="col-12"
                trueText="Activa"
                falseText="Inactiva"
                handleChange
                invert
              />
            </>}
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12">
              <label className="label">Ubicación</label>
              <SelectCoordinateMap
                key={city}
                showButtons={false}
                onAccept={onClick}
                cities={cities}
                city={city}
                initialPos={station && station.latlng ? [station.latlng.split(',')[0], station.latlng.split(',')[1]] : null}
                station={true}
                showShadow={false}
                route={route}
              />
            </div>
            <WafoFormInput
              name="address"
              label="Dirección"
              placeholder="Calle, colonia, C.P."
              customClass="col-12"
              validations={{ required: false }}
              extraProps={{
                disabled: true,
                style: { display: 'none' },
              }}
            >
              <input className="form-control" placeholder="Calle, colonia, C.P." value={station.address} disabled />
            </WafoFormInput>
            <WafoFormInput
              name="latlng"
              label="Latitud y Longitud"
              placeholder="0, 0"
              customClass="col-12"
              validations={{
                required: {
                  value: true,
                  message: 'Campo requerido. Marca la ubicación en el mapa.',
                },
              }}
              extraProps={{
                disabled: true,
                style: { display: 'none' },
              }}
            >
              <input className="form-control" placeholder="0.0, 0.0" value={station.latlng} disabled />
            </WafoFormInput>
          </div>
        </div>
      </WafoForm>
    </div>
  );
};

FormLine.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.any,
  cities: PropTypes.array,
  onCityChange: PropTypes.func,
  tabs: PropTypes.string,
  routes: PropTypes.array,
  onParentChange: PropTypes.func,
  loading: PropTypes.shape({
    set: PropTypes.func,
    stop: PropTypes.func,
  }),
  updateRoutes: PropTypes.func,
};

FormLine.defaultProps = {
  onSubmit: f => f,
  initialValues: {},
  cities: [],
  onCityChange: f => f,
  tabs: '',
  routes: [],
  onParentChange: f => f,
  loading: {
    set: f => f,
    stop: f => f,
  },
  updateRoutes: f => f
};

export default FormLine;
